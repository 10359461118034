<template>
  <div id="footer">
    <div class="box links">
      <h1>Links:</h1>
      <ul>
        <li><a href="http://www.mkv.eu" target="_blank">Musikkapelle</a></li>
        <li><a href="https://www.villingendorf.de" target="_blank">Villingendorf</a></li>
      </ul>
      <br />
      <h2><a href="http://mkv.eu/cmsj3/index.php/impressum" target="_blank">Impressum</a></h2>
    </div>
    <div class="box social">
      <a href="https://www.instagram.com/musikkapelle_villingendorf_e.v/" target="_blank"><img src="../assets/instagram.png" /></a>
      <a href="https://www.facebook.com/MusikkapelleVillingendorf" target="_blank"><img src="../assets/facebook.png" /></a>
    </div>
    <div class="box map">
      <gmaps-map
        :options="{
          center: { lat: 48.203, lng: 8.590022 },
          zoom: 14,
        }"
      />
    </div>
  </div>
</template>

<script>
import { gmapsMap } from 'x5-gmaps'
export default {
  name: 'Footer',
  components: {
    gmapsMap,
  },
}
</script>

<style scoped>
  div#footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 70vw;
    margin: auto;
    margin-top: 20px;
  }

  div#footer div.box {
    height: 300px;
  }

  div.box.links {
    width: 20%;
    margin: 0px;
    height: 100%;
  }

  div.box.social {
    width: 10%;
    margin: 0px;
    height: 100%;
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: none;
  }

  div.box.map {
    width: 60%;
    margin: 0px;
    height: 100%;
  }

  div.box.links a {
    display: block;
    text-decoration: none;
    color: black;
    margin: 4px 0px;
    line-height: 110%;
  }

  div.box.links ul {
    list-style-type: none;
    padding: 0px;
  }

  div.box.links ul li a:before {
    content: "- ";
  }

  div.box.social img {
    width: 70%;
    margin: auto;
    display: block;
  }

  @media (max-width: 1200px) {
    div#footer {
      width: 85vw;
    }
  }

  @media (max-width: 900px) {
    div#footer {
      width: 90vw;
    }

    div.box.links {
      width: 70%;
    }

    div.box.social {
      width: 30%;
    }

    div.box.map {
      margin: 20px 0px;
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    div#footer {
      width: 95vw;
    }
  }
</style>