<template>
  <div class="box">
    <h1>Buchen:</h1>
    <h2>Ihre Daten:</h2>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          label="Ihr Name"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Ihre Telefonnummer"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Ihre E-Mail-Adresse"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
            label="weitere Anmerkungen (optional)"
        />
      </v-col>
    </v-row>
    <h2>Daten des Empfängers</h2>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Name des Empfängers"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Adresse des Empfängers"
        />
      </v-col>
    </v-row>
    <h2>Daten zur Musiküberüaschung</h2>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          label="Auswahl des Datums"
          :items="['3. Advent, 13.12.2020', '4. Advent, 20.12.2020']"
        />
      </v-col>
      <v-col
          cols="12"
          md="6"
      >
        <v-select
            v-model="musikstuecke"
            label="Musikstücke"
            :items="auswahlmusikstuecke"
            multiple
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
          label="persönliche Grußbotschaft an den Empfänger (optional)"
        />
      </v-col>
    </v-row>
    <h2>Preis / weitere Spende</h2>
    Die {{musikstuecke.length}} ausgewählten Musikstücke kosten {{min}}€. Sie dürfen jedoch auch mehr spenden:
    <v-row>
      <v-col>
        <v-slider
          v-model="slider"
          :label="`Ich möchte gerne insgesamt `+slider+`€ bezahlen`"
          :min="min"
          max="100"
          step="5"
        />
      </v-col>
    </v-row>

    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">

      <!-- Identify your business so that you can collect the payments. -->
      <input type="hidden" name="business" value="info@it-kimmig.de">
      <input type="hidden" name="de" value="__ppLang__">

      <!-- Specify a Donate button. -->
      <input type="hidden" name="cmd" value="_donations">

      <!-- Specify details about the contribution -->
      <input type="hidden" name="item_name" value="Musik vor der Tur">
      <input type="hidden" name="item_number" value="Sebastian Jung">
      <input type="hidden" name="amount" :value="slider+'.00'">
      <input type="hidden" name="currency_code" value="EUR">

      <!-- Display the payment button. -->
      <v-btn
          block
          type="submit"
          name="submit"
      >
        Buchen und <b style="font-size:150%;margin:0px 10px;">{{slider}}€</b> per PayPal bezahlen
      </v-btn>
    </form>
    <p style="margin-top:20px;">Sie können alternativ auch Bar bei Karina Nester oder Sebastian Jung bezahlen:</p>
    <v-btn
        block
    >
      Buchen und später Bar bezahlen
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Booking',

  data: () => ({
    slider: 0,
    musikstuecke: [],
    auswahlmusikstuecke: [
      'ABC - die Katze lief im Schnee',
      'Hänschen klein',
      'Alle meine Entchen',
    ],
  }),

  computed: {
    min () {
      return 5 + 5 * this.musikstuecke.length
    },
  },
}
</script>

<style scoped>
  p {
    margin-top: 8px;
  }
</style>