<template>
  <div id="title">
    <div class="blur" />
    <div class="content">
      <img src="../assets/Musik_Logo.png" >
      <div class="mytitle">Musik vor der Tür</div>
      <div class="quelle">
        Bild: <a href="https://pixabay.com/de/photos/musik-trompete-strassenmusiker-2872166/" target="_blank">Pixabay</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Title"
}
</script>

<style scoped>
  div#title {
    background-image: url('../assets/music-2872166_1280.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50vh;
    position: relative;
    box-shadow: 0px 0px 12px black;
  }

  div.blur {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(55,30,0,0.5);
    z-index: 0;
  }

  div.content {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin-left: 20px;
    height: 35vh;
    width: auto;
  }

  div.mytitle {
    font-family: 'Kalam', cursive;
    display: inline-block;
    color: white;
    text-shadow: 0px 0px 2px black, 0px 0px 4px black, 0px 0px 12px black;
    font-size: 120px;
    line-height: 125%;
    margin-left: 40px;
    margin-right: 20px;
  }

  @media (max-width: 900px) {
    div#title {
      height: 42vh;
    }

    img {
      height: 28vh;
    }

    div.mytitle {
      font-size: 80px;
      margin-left: 30px;
    }
  }

  @media (max-width: 600px) {
    div#title {
      height: 35vh;
    }

    img {
      height: 18vh;
    }

    div.mytitle {
      font-size: 60px;
      margin-left: 10px;
    }
  }

  div.quelle {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 70%;
  }

  div.quelle, div.quelle a {
    color: white;
    text-shadow: 0px 0px 4px black;
    font-style: italic;
  }
</style>