import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import x5GMaps from 'x5-gmaps'

Vue.config.productionTip = false

Vue.use(x5GMaps, 'AIzaSyD7zPentqC1Mk6_k5dnOZUkAaoLic3j1TQ')

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
