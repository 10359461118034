<template>
  <v-app>
    <Title />
    <Description />
    <Booking />
    <Footer />
  </v-app>
</template>

<script>
import Title from '@/components/Title';
import Description from '@/components/Description';
import Booking from '@/components/Booking';
import Footer from '@/components/Footer';
export default {
  name: 'App',

  components: {
    Footer,
    Booking,
    Description,
    Title
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');

html, body {
  margin: 0px;
  padding: 0px;
}

body {
  background-image: url('./assets/music-1440844_1920.jpg');
  background-size: cover;
  background-position: center;
}

.theme--light.v-application {
  background: rgba(255, 255, 255, 0.8) !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
}

div.box {
  width: 70vw;
  margin: auto;
  margin-top: 20px;
  font-size: 20px;
  text-align: justify;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

h1 {
  font-family: 'Kalam', cursive;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}

h2 {
  font-size: 22px;
  font-weight: bold;
}

p {
  margin-top: 8px;
}

@media (max-width: 1200px) {
  div.box {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  div.box {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  div.box {
    width: 95vw;
  }
}

.v-list-item__title {
  text-align: left;
}
</style>